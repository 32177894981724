// src/pages/account.js
import React from 'react';
import { Router } from '@reach/router';
// import { Link } from 'gatsby';
import { login, isAuthenticated, getProfile } from '../utils/auth';
// import Dashboard from '../components/dashboard';
import Dashboard from '../components/dashboard';

// const Home = ({ user }) => {
//   return <p>Hi, {user.name ? user.name : 'friend'}!</p>;
// };
// const Settings = () => <p>Settings</p>;
// const Billing = () => <p>Billing</p>;

const Account = () => {
  if (!isAuthenticated()) {
    login();
    return <p>Redirecting to login...</p>;
  }

  const user = getProfile();

  return (
    <>
      <Router>
        {/* <Home path="/account/" user={user} />
        <Settings path="/account/settings" />
        <Billing path="/account/billing" /> */}
        <Dashboard path="/account/" user={user} />
      </Router>
    </>
  );
};

export default Account;
