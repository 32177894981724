import React from 'react';
import PropTypes from 'prop-types';
import { logout } from '../utils/auth';

const NavBar = props => {
  const { labels } = props;
  return (
    <header>
      <div className="logout">
        <a
          href="#logout"
          onClick={evt => {
            logout();
            evt.preventDefault();
          }}
        >
          <button type="button">{labels.LOG_OUT}</button>
        </a>
      </div>
      <div className="titles">
        <div className="rewards-title">{labels.REWARDS_TITLE}</div>
        <div className="dashboard-title">{labels.DASHBOARD}</div>
      </div>
    </header>
  );
};

NavBar.defaultProps = {
  labels: {},
};

NavBar.propTypes = {
  labels: PropTypes.shape({
    DASHBOARD: PropTypes.string,
    LOG_OUT: PropTypes.string,
    REWARDS_TITLE: PropTypes.string,
  }),
};

export default NavBar;
