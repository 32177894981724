import React from 'react';
import PropTypes from 'prop-types';
// import Dump from '../utils/dump';

import '../styles/summary.scss';
// import PCLogo from '../../static/img/presidents-club.svg';

const Summary = props => {
  const { data, labels } = props;

  const epicorRecord = data.value[0];
  const lookupRecord = data.records[0].fields;
  let corporateName = epicorRecord.UD_FP_Customer_CorporateName || null;
  const fullName = lookupRecord.FullName;
  const fusionPoints = epicorRecord.Calculated_SumActualPoints;
  const logoUrl = lookupRecord.Logo && lookupRecord.Logo[0].url;
  const lastUpdate = epicorRecord.Calculated_LastPointsUpdateDate || null;
  const presidentsClub = epicorRecord.Calculated_PCMember || false;

  if (corporateName) {
    /* if there is a country identifier in brackets, e.g. Company XYZ (USA),
       clean up string to display only the company name */
    const displayName = corporateName.trim();
    const idx = displayName.lastIndexOf(')');
    const idx2 = displayName.lastIndexOf('(');
    if (idx2 > 0 && idx > idx2) {
      corporateName = displayName.substring(0, idx2 - 1).trim();
    }
  }

  return (
    <>
      {data && corporateName && (
        <>
          {/* <Dump src={data} /> */}
          <div className="summary">
            <div className="welcome">{labels.WELCOME}</div>
            {presidentsClub && (
              <>
                <div className="hr">
                  <hr />
                </div>
                <div className="club">
                  {/* <img src={PCLogo} alt="" /> */}
                  <div className="plaque">Presidents Club member</div>
                </div>
              </>
            )}
            <div className="hr">
              <hr />
            </div>
            <div className="id">
              <div className="user">
                <div className="name">{fullName}</div>
                <div className="company">{corporateName}</div>
              </div>
              {logoUrl && (
                <div className="logo">
                  <img src={logoUrl} alt="" />
                </div>
              )}
            </div>
            <div className="hr">
              <hr />
            </div>
            <div className="date">{labels.BALANCE}</div>
            <div className="points">
              <div className="label">
                {labels.TOTAL}{' '}
                {lastUpdate && (
                  <span className="last-update">(as of {lastUpdate.substring(0, 10)})</span>
                )}
              </div>
              <div className="value">{fusionPoints}</div>
            </div>
          </div>
        </>
      )}

      {!corporateName && (
        <div className="unavailable">
          <p>Your balance is not available at this time.</p>
          <p>
            <a
              href={`mailto:${labels.HELP_EMAIL}?subject=${encodeURI(labels.SUBJECT_HELP_ACCOUNT)}`}
            >
              {labels.CONTACT_HELP}
            </a>
          </p>
        </div>
      )}
    </>
  );
};

Summary.defaultProps = {
  data: {},
  error: {},
  labels: {},
};

Summary.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        Calculated_LastPointsUpdateDate: PropTypes.string,
        Calculated_PCMember: PropTypes.bool,
        Calculated_SumActualPoints: PropTypes.string,
        UD_FP_Customer_CorporateName: PropTypes.string,
      }),
    ),
    records: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          FullName: PropTypes.string,
          Tier: PropTypes.string,
          Logo: PropTypes.arrayOf(
            PropTypes.shape({
              url: PropTypes.string,
            }),
          ),
        }),
      }),
    ),
  }),
  error: PropTypes.shape({
    msg: PropTypes.object,
  }),
  labels: PropTypes.shape({
    CONTACT_HELP: PropTypes.string,
    BALANCE: PropTypes.string,
    HELP_EMAIL: PropTypes.string,
    SUBJECT_HELP_ACCOUNT: PropTypes.string,
    TOTAL: PropTypes.string,
    WELCOME: PropTypes.string,
  }),
};

export default Summary;
