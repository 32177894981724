import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import fetchPonyfill from 'fetch-ponyfill';
import { getProfile, isAuthenticated, login, logout } from '../utils/auth';
import Layout from './layout';
import Summary from './summary';
import NavBar from './navbar';
import '../styles/dashboard.scss';

import spinner from '../../static/img/spinner-2s-120px.gif';

/*
  avoid whatwg-fetch, instead use fetch-ponyfill 
  https://github.com/gatsbyjs/gatsby/issues/8612
  github.com/matthew-andrews/isomorphic-fetch/issues/174
*/

const { fetch } = fetchPonyfill();

const displayError = (errorCode, errorMessages) => {
  if (errorCode === 404) {
    return errorMessages.NO_RECORD_FOUND;
  }
  return errorMessages.RETRIEVAL_ERROR;
};

/* see https://auth0.com/blog/securing-gatsby-with-auth0/ for Gatsby with Auth0 */

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCode: null,
      apiData: null,
      apiError: null,
      isLoading: true,
      user: null,
    };
  }

  componentDidMount() {
    const user = getProfile();
    /* in some edge cases, such as using Brave browser, user can refresh the page but user data
       is not preserved, nullifying the ability to lookup data, so it's better to log them out */
    if (user.email) {
      this.getData(user);
    } else {
      logout();
    }
  }

  getData = user => {
    let error = false;
    let responseCode = null;
    this.setState({ isLoading: true });
    fetch(`/.netlify/functions/getCustomer?userid=${user.sub}`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then(res => {
        responseCode = res.status;
        if (!res.ok) {
          error = true;
        }
        return res.json();
        // return fullResponse;
      })
      .then(json => {
        if (error) {
          this.setState({
            apiCode: responseCode,
            apiData: null,
            apiError: json.msg,
            isLoading: false,
            user,
          });
        } else {
          this.setState({
            apiCode: responseCode,
            apiData: json.msg,
            apiError: null,
            isLoading: false,
            user,
          });
        }
      })
      .catch(err => {
        this.setState({
          apiData: null,
          apiError: err,
          isLoading: false,
          user: null,
        });
      });
  };

  render() {
    const { apiCode, apiError, apiData, isLoading, user } = this.state;
    const {
      data: {
        cms: { fcSites },
      },
    } = this.props;
    const site = fcSites[0];

    if (!isAuthenticated()) {
      login();
      return <p>Redirecting to login...</p>;
    }

    return (
      <Layout childClass="dashboard">
        <div className="top">
          <NavBar labels={site.labels} />

          {isLoading && (
            <>
              <div className="loading">
                <p>
                  <img src={spinner} height="120" width="120" alt="" />
                </p>
                <p>Loading...</p>
              </div>
            </>
          )}

          {apiError && (
            <div className="error">
              <p>{displayError(apiCode, site.errorMessages)}</p>
              <p>
                <a
                  href={`mailto:${site.labels.HELP_EMAIL}?subject=${encodeURI(
                    site.labels.SUBJECT_HELP_ACCOUNT,
                  )}`}
                >
                  {site.labels.CONTACT_HELP}
                </a>
              </p>
            </div>
          )}
          {apiData && (
            <Summary
              data={apiData}
              error={apiError}
              isLoading={isLoading}
              labels={site.labels}
              status={apiCode}
              user={user}
            />
          )}
        </div>
        <div className="bottom">
          <footer>
            <span>
              {`${site.labels.HELP_POINTS} `}
              <a
                href={`mailto:${site.labels.HELP_EMAIL}?subject=${encodeURI(
                  site.labels.SUBJECT_HELP,
                )}`}
              >
                {site.labels.CONTACT_US}
              </a>
            </span>
          </footer>
        </div>
      </Layout>
    );
  }
}

Dashboard.defaultProps = {
  data: {},
  user: {},
};

Dashboard.propTypes = {
  data: PropTypes.shape({
    cms: PropTypes.shape({
      fcSites: PropTypes.array,
    }),
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        cms {
          fcSites {
            fcContent {
              title
              block
            }
            errorMessages
            labels
          }
        }
      }
    `}
    render={data => <Dashboard {...props} data={data} />}
  />
);
